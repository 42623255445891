(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/verify/assets/javascripts/verify.js') >= 0) return;  svs.modules.push('/components/lb-utils/verify/assets/javascripts/verify.js');
"use strict";

let VerifyValue;
let VerifyArray;
let VerifyObject;
if (svs.isServer) {
  const {
    VerifyValue: _VerifyValue,
    VerifyArray: _VerifyArray,
    VerifyObject: _VerifyObject
  } = require('./verify-classes.es6');
  VerifyValue = _VerifyValue;
  VerifyArray = _VerifyArray;
  VerifyObject = _VerifyObject;
} else {
  const {
    VerifyValue: _VerifyValue,
    VerifyArray: _VerifyArray,
    VerifyObject: _VerifyObject
  } = svs.components.lbUtils.verify.classes;
  VerifyValue = _VerifyValue;
  VerifyArray = _VerifyArray;
  VerifyObject = _VerifyObject;
}
const verifyValue = (value, loggger) => new VerifyValue(value, loggger);
const verifyArray = (array, loggger) => new VerifyArray(array, loggger);
const verifyObject = (object, loggger) => new VerifyObject(object, loggger);
const _exports = {
  verifyValue,
  verifyArray,
  verifyObject
};
if (svs.isServer) {
  module.exports = _exports;
} else {
  setGlobal('svs.components.lbUtils.verify', _exports);
}

 })(window);