(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/verify/assets/javascripts/verify-classes.js') >= 0) return;  svs.modules.push('/components/lb-utils/verify/assets/javascripts/verify-classes.js');
"use strict";

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

let VerifyType;
if (svs.isServer) {
  const {
    VerifyType: _VerifyType
  } = require('./constants.es6');
  VerifyType = _VerifyType;
} else {
  const {
    VerifyType: _VerifyType
  } = svs.components.lbUtils.verify.constants;
  VerifyType = _VerifyType;
}
const DummyLog = {
  warn: () => {}
};
var _value = new WeakMap();
var _logger = new WeakMap();
var _shouldThrow = new WeakMap();
var _notValid = new WeakMap();
class VerifyBase {
  constructor(value, logger) {
    _classPrivateFieldInitSpec(this, _value, void 0);
    _classPrivateFieldInitSpec(this, _logger, void 0);
    _classPrivateFieldInitSpec(this, _shouldThrow, void 0);
    _classPrivateFieldInitSpec(this, _notValid, void 0);
    _classPrivateFieldSet(_value, this, value);
    _classPrivateFieldSet(_logger, this, logger || DummyLog);
    _classPrivateFieldSet(_shouldThrow, this, true);
    _classPrivateFieldSet(_notValid, this, false);
  }
  get value() {
    return _classPrivateFieldGet(_value, this);
  }
  get logger() {
    return _classPrivateFieldGet(_logger, this);
  }
  dontThrow() {
    _classPrivateFieldSet(_shouldThrow, this, false);
    return this;
  }
  shouldThrow() {
    _classPrivateFieldSet(_shouldThrow, this, true);
    return this;
  }
  get isValid() {
    return !_classPrivateFieldGet(_notValid, this);
  }
  throwError(msg) {
    _classPrivateFieldGet(_logger, this).warn(msg);
    throw new Error(msg);
  }
  validate(ctx, fn, msg) {
    if (_classPrivateFieldGet(_notValid, this) || fn()) {
      _classPrivateFieldSet(_notValid, this, true);
      try {
        this.throwError(msg);
      } catch (err) {
        if (_classPrivateFieldGet(_shouldThrow, this)) {
          throw err;
        }
      }
    }
    return ctx;
  }
}
class VerifyValueBase extends VerifyBase {
  isNotNullOrUndefiend() {
    return this.validate(this, () => this.value === undefined || this.value === null, 'Value can not be null or undefined');
  }
}
class VerifyValue extends VerifyValueBase {
  isString() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'string' && !(this.value instanceof String), 'Value must be a string');
  }
  isNumber() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'number', 'Value must be a number');
  }
  isBool() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'boolean', 'Value must be a boolean');
  }
  isFunc() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'function', 'Value must be a function');
  }
  isArray() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'object' || this.value.constructor.name !== 'Array', 'Value must be an array');
  }
  isObject() {
    return this.validate(this.isNotNullOrUndefiend(), () => typeof this.value !== 'object' || this.value.constructor.name === 'Array', 'Value must be a object');
  }
  isStringAndNotNullOrEmpty() {
    return this.validate(this.isString(), () => this.value.length < 1, 'String can not be empty');
  }
  isElement() {
    return this.validate(this.isObject(), () => {
      const {
        nodeType
      } = this.value;
      return nodeType !== 1;
    }, 'Object must be a element');
  }
  asArray() {
    this.isArray();
    return new VerifyArray(this.value);
  }
  asObject() {
    this.isObject();
    return new VerifyObject(this.value);
  }
  static validateValue(type, value, logger) {
    switch (type) {
      case VerifyType.String:
        new VerifyValue(value, logger).isString();
        break;
      case VerifyType.Number:
        new VerifyValue(value, logger).isNumber();
        break;
      case VerifyType.Bool:
        new VerifyValue(value, logger).isBool();
        break;
      case VerifyType.Func:
        new VerifyValue(value, logger).isFunc();
        break;
      case VerifyType.Object:
        new VerifyValue(value, logger).isObject();
        break;
      case VerifyType.Array:
        new VerifyValue(value, logger).isArray();
        break;
      case VerifyType.NotEmptyString:
        new VerifyValue(value, logger).isStringAndNotNullOrEmpty();
        break;
      case VerifyType.Element:
        new VerifyValue(value, logger).isElement();
        break;
      default:
        new VerifyValue(value, logger).throwError("Unknown type \"".concat(type, "\""));
        break;
    }
  }
}
class VerifyArray extends VerifyValueBase {
  constructor(array, logger) {
    super(array, logger);
    new VerifyValue(array).isArray();
  }
  schema(schema) {
    VerifyArray.validateSchema(schema, this.value, this.logger);
  }
  hasValues() {
    return this.validate(this, () => this.value.length < 1, 'Array must have values');
  }
  index(index) {
    this.validate(this, () => !this.value[index], "Array must contain index \"".concat(index, "\""));
    return new VerifyValue(this.value[index]);
  }
  static validateSchema(schema, value, logger) {
    new VerifyArray(schema);
    const valueTypes = [VerifyType.String, VerifyType.Number, VerifyType.Bool, VerifyType.Func];
    schema.forEach((type, index) => {
      if (valueTypes.includes(type)) {
        VerifyValue.validateValue(type, value[index], logger);
      } else if (new VerifyValue(type).dontThrow().isObject().isValid) {
        VerifyObject.validateSchema(type, value[index], logger);
      } else if (new VerifyValue(type).dontThrow().isArray().isValid) {
        VerifyArray.validateSchema(type, value[index], logger);
      }
    });
  }
}
class VerifyObject extends VerifyValueBase {
  constructor(object, logger) {
    super(object, logger);
    new VerifyValue(object).isObject();
  }
  schema(schema) {
    VerifyObject.validateSchema(schema, this.value, this.logger);
    return this;
  }
  hasValues() {
    return this.validate(this, () => Object.values(this.value).length < 1, 'Object must have values');
  }
  field(key) {
    this.validate(this, () => !this.value[key], "Object must contain field \"".concat(key, "\""));
    return new VerifyValue(this.value[key]);
  }
  static validateSchema(schema, value, logger) {
    new VerifyObject(schema);
    const schemaEntries = Object.entries(schema);
    const valueTypes = [VerifyType.String, VerifyType.Number, VerifyType.Bool, VerifyType.Func];
    schemaEntries.forEach(_ref => {
      let [key, type] = _ref;
      if (valueTypes.includes(type)) {
        VerifyValue.validateValue(type, value[key], logger);
      } else if (new VerifyValue(type).dontThrow().isObject().isValid) {
        VerifyObject.validateSchema(type, value[key], logger);
      } else if (new VerifyValue(type).dontThrow().isArray().isValid) {
        VerifyArray.validateSchema(type, value[key], logger);
      }
    });
  }
}
const _exports = {
  VerifyBase,
  VerifyValueBase,
  VerifyValue,
  VerifyArray,
  VerifyObject
};
if (svs.isServer) {
  module.exports = _exports;
} else {
  setGlobal('svs.components.lbUtils.verify.classes', _exports);
}

 })(window);