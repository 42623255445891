(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/verify/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/lb-utils/verify/assets/javascripts/constants.js');
"use strict";

const VerifyType = {
  String: 'string',
  Number: 'number',
  Bool: 'bool',
  Func: 'func',
  Object: 'object',
  Array: 'array',
  NotEmptyString: 'stringNotEmpty',
  Element: 'element'
};
const _exports = {
  VerifyType
};
if (svs.isServer) {
  module.exports = _exports;
} else {
  setGlobal('svs.components.lbUtils.verify.constants', _exports);
}

 })(window);